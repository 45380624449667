<template>
  <v-container fluid grid-list-sm>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>
          <span class="font-weight-medium">
            {{ $t('ITEM.CREATE') }} {{ $t('ITEM.NEW') | toLower }}
            {{ $t('POSTS.ITEM') }}
          </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn dark color="indigo" @click="$router.go(-1)">
          {{ $t('BUTTON.BACK') }}
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
    </v-card>
    <v-card class="mt-3">
      <v-card-text>
        <v-row>
          <v-col md="8" xs="12">
            <v-card outlined>
              <v-card-text>
                <language-tabs-optional
                  v-if="form"
                  ref="languageTabs"
                  v-model="form.translations"
                  :errors="errors.get('translations')"
                  #default="{ translation, index, language, on, ...other }"
                  eager
                >
                  <main-form
                    :value="translation"
                    :language-id="language.id"
                    v-bind="other"
                    v-on="on"
                    @update-prop="
                      clearErrorByName($event, `translations.${index}`)
                    "
                    @valid="setValid(`translations.${index}`, $event)"
                  ></main-form>
                </language-tabs-optional>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col md="4" xs="12">
            <v-card outlined>
              <v-card-text>
                <v-form
                  @keydown.native="clearError($event)"
                  @change.native="clearError($event)"
                >
                  <v-row>
                    <v-col cols="4">
                      <v-subheader>{{ $t('ITEM.AUTHOR') }} *</v-subheader>
                    </v-col>
                    <v-col cols="8">
                      <v-autocomplete
                        v-model="form.author_id"
                        :loading="!!!buildingComplexes.length"
                        :item-text="(item) => item.name"
                        :error-messages="errors.get('author_id')"
                        item-value="id"
                        :items="authors"
                        append-outer-icon="mdi-close"
                        @change="errors.clear('author_id')"
                        @click:append-outer="form.author_id = null"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row>
                    <v-col cols="4">
                      <v-subheader>{{ $t('ITEM.IMAGE') }}</v-subheader>
                    </v-col>
                    <v-col cols="8">
                      <v-skeleton-loader
                        :loading="!form.upload"
                        boilerplate
                        max-width="400"
                        type="image"
                      >
                        <div>
                          <v-img
                            v-if="form.upload"
                            :src="form.upload.url"
                            max-width="400"
                          />
                        </div>
                      </v-skeleton-loader>
                      <autoupload-file-input
                        v-model="form.upload"
                        :label="$t('ITEM.IMAGE')"
                        :url="`uploads/posts`"
                        :error-messages="errors.get('image')"
                        prepend-icon="mdi-camera"
                        chips
                        show-size
                        destroyed
                        @input="form.upload_id = $event"
                      ></autoupload-file-input>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row>
                    <v-col cols="8">
                      <v-subheader>
                        <v-switch
                          v-model="showToAll"
                          inset
                          :label="$t('ITEM.SHOW_TO_ALL')"
                          :error-messages="errors.get('show_to')"
                          @change="
                            () => {
                              showToAll
                                ? delete form.building_complex_ids
                                : (form.building_complex_ids = []);
                              errors.clear('show_to');
                              errors.clear('building_complex_ids');
                            }
                          "
                        ></v-switch>
                      </v-subheader>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row>
                    <v-col>
                      <v-autocomplete
                        v-model="form.building_complex_ids"
                        :loading="!!!buildingComplexes.length"
                        :items="buildingComplexes"
                        :item-text="(item) => item.name"
                        :error-messages="errors.get('building_complex_ids')"
                        :search-input.sync="buildingComplexSearch"
                        item-value="id"
                        multiple
                        chips
                        small-chips
                        :label="`${$t('POSTS.BUILDING_COMPLEX_FOR_POST')} *`"
                        :disabled="!!showToAll"
                        append-outer-icon="mdi-close"
                        @click:append-outer="clearAutocomplete"
                        @change="
                          () => {
                            buildingComplexSearch = '';
                            errors.clear('building_complex_ids');
                          }
                        "
                      >
                        <template v-slot:selection="data">
                          <v-chip
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            close
                            @click="data.select"
                            @click:close="remove(data.item)"
                          >
                            {{ data.item.name }}
                          </v-chip>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn
              normal
              class="float-right"
              color="primary"
              :disabled="
                !(!!form.translations && form.translations.length && valid)
              "
              @click="submit"
              >{{ $t('BUTTON.SAVE') }}</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { toLower } from '@/filters/index.js';
import errors from '@/mixins/errors';
import MainForm from '@/modules/posts/components/MainForm';
import AutouploadFileInput from '@/components/AutouploadFileInput.vue';
import moment from 'moment';
import { requiredSelectArray } from '@/rules';
import { MANAGEMENT_COMPANY, BUILDING_COMPLEX } from '../common/show_to';
import { PostsService } from '@/services/posts.service';
import { BuildingComplexesService } from '@/services/building-complexes.service';

export default {
  name: 'PostCreatePage',

  filters: {
    toLower,
  },

  components: {
    MainForm,
    AutouploadFileInput,
    LanguageTabsOptional: () => import('@/components/OptionalLanguageTabs.vue'),
  },

  mixins: [errors],

  props: {},

  data: () => {
    return {
      imagePreview: '',
      authors: [],
      tab: null,
      valid: {},
      languages: [],
      buildingComplexes: [],
      companySearch: '',
      form: {
        upload: null,
        show_to: MANAGEMENT_COMPANY,
      },
      rules: {
        requiredSelectArray,
      },
      buildingComplexSearch: '',
    };
  },

  watch: {
    imageSrc(v) {
      this.imagePreview = v;
    },
  },

  computed: {
    ...mapGetters('localization', ['lang']),

    dateNow() {
      return moment(Date.now()).format('DD.MM.YYYY');
    },

    imageSrc() {
      if (!this.form.image_path) {
        return '';
      }

      const coreApi = this.$config.get('app.coreApi');

      return `${coreApi}/../storage/${this.form.image_path}`;
    },

    showToAll: {
      get() {
        return this.form.show_to === MANAGEMENT_COMPANY;
      },
      set(v) {
        this.form.show_to = v ? MANAGEMENT_COMPANY : BUILDING_COMPLEX;
      },
    },
  },

  created() {
    this.retrieveLanguages().then((response) => {
      this.languages = response.data;
    });

    BuildingComplexesService.getList().then(({ data }) => {
      this.buildingComplexes = data.data;
    });

    PostsService.getAuthorCompanies()
      .then(({ data }) => {
        this.authors = data;
      })
      .catch((error) => {
        this.error = error;
      });
  },

  methods: {
    setValid(index, value) {
      this.$set(this.valid, index, value);
    },

    save() {
      PostsService.create({
        ...this.form,
        is_company_post: 1,
      })
        .then(() => {
          this.$router.push({ name: 'posts' });
        })
        .catch((response) => {
          this.handleErrorsByResponse(response);
        });
    },

    ...mapActions('localization', ['retrieveLanguages']),

    submit() {
      this.errors.clear();
      this.save();
    },

    clearError(event, prefix) {
      let name = event.target.name;

      if (name) {
        this.errors.clear(prefix ? `${prefix}.${name}` : name);
      }
    },

    remove(item) {
      const index = this.form.building_complex_ids.indexOf(item.id);
      if (index >= 0) this.form.building_complex_ids.splice(index, 1);
    },

    clearAutocomplete() {
      this.form.building_complex_ids.splice(
        0,
        this.form.building_complex_ids.length
      );
    },

    handleFileInput(event) {
      if (!event) {
        return;
      }

      let fileReader = new FileReader();

      fileReader.onload = (e) => {
        this.imagePreview = e.target.result;
        this.updateValue('image');
      };

      fileReader.readAsDataURL(new Blob([event]));

      this.form.upload = event;
    },

    clearImage() {
      this.form.upload = null;
      this.imagePreview = '';
      this.updateValue('upload');
    },
  },
};
</script>
